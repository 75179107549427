<!-- RESULT COUNT -->
<div class="result-count dropdown-filter">
   <span>
      <nz-select
         class="dd-design"
         [(ngModel)]="searchService.filters.GROUPS.sort"
         (ngModelChange)="onFilterChanged()">
         <nz-option
            nzValue="descending"
            nzLabel="Newest"></nz-option>
         <nz-option
            nzValue="ascending"
            nzLabel="Oldest"></nz-option>
      </nz-select>
   </span>
   <span style="float: right">
      <div *ngIf="isLoaded || (total > 0 && !isLoaded)">
         {{ resultCount }}
         <button
            *ngIf="searchService.suggestedSearch.show"
            (click)="searchService.performSuggestedSearch()"
            class="suggested-search">
            Did you mean {{ searchService.suggestedSearch.keyword }}?
         </button>
      </div>
   </span>
</div>
<app-scroll-container>
   <ng-template bodyTemplate>
      <div
         [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
         infiniteScroll
         [infiniteScrollDistance]="scrollDistance"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         data-scrollable-container
         [attr.data-component]="'ncx/search/page/results/groups'">
         <div class="search-result-body">
            <!-- LOADER -->
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isLoaded"></app-loader-component>

            <!-- CARD TO DISPLAY GROUP SEARCH RESULTS -->
            <div class="search-group-body">
               <ncx-group-search
                  class="card-style"
                  *ngFor="let group of searchResults; let index = index"
                  (viewGroup)="viewGroup(group, index)"
                  [group]="group"></ncx-group-search>
            </div>
            <div *ngIf="!searchResults.length && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Groups Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </div>
   </ng-template>
</app-scroll-container>
