<!-- STORY INFORMATION -->
<div
   [ngClass]="{ 'ncx-story-header': true, 'asStory': storyOnly }"
   data-component="shared/cards/story">
   <a
      target="_blank"
      class="title"
      [href]="storyLink"
      nz-tooltip
      nzTooltipPlacement="bottomLeft"
      [nzTooltipMouseEnterDelay]="0.5"
      [nzTooltipTitle]="story.storyTitle">
      <!-- STATUS -->
      <app-story-status
         [showStatusText]="false"
         [status]="story.storyState"></app-story-status>

      <!-- TITLE -->
      <span [innerHtml]="story.snippet?.title?.trim() || story.storyTitle | safeHtml"></span>
   </a>

   <div
      class="time-ago"
      *ngIf="showPostTimeAgo && !storyOnly">
      <!-- STORY CREATED TIME (if no post exists) -->
      <ng-container *ngIf="!hasPost">
         {{ calculateDiff(story.updateDateTime) }}
      </ng-container>

      <!-- LATEST POST TIME -->
      <ng-container *ngIf="hasPost">
         {{ calculateDiff(post.updateDateTime) }}
      </ng-container>
   </div>
</div>

<ng-container *ngIf="hasPost && !storyOnly">
   <!-- LATEST POST TITLE -->
   <ng-container *ngIf="showPostTitle">
      <div class="post-title">
         <a
            target="_blank"
            [href]="postLink"
            nz-tooltip
            nzTooltipPlacement="bottomLeft"
            [nzTooltipMouseEnterDelay]="0.5"
            [nzTooltipTitle]="formatTitle(post.postTitle)"
            [innerHTML]="post.snippet?.title?.trim() || post.postTitle.trim() | safeHtml"></a>
      </div>
      <div class="updated-by">
         <ng-container *ngIf="post.updateUser?.firstName || post.updateUser?.displayName">
            <button
               class="name"
               nz-popover
               [nzPopoverContent]="profileCardPerson"
               [nzPopoverPlacement]="index === 0 ? 'bottomLeft' : 'topLeft'">
               {{ Common.formatName(post.updateUser, true) }}
            </button>

            <span class="time">
               on {{ utcToLocal(post.updateDateTime, 'DATE') | date: 'M/d/yy' }} at
               {{ utcToLocal(post.updateDateTime, 'TIME') }}
            </span>
         </ng-container>

         <ng-template #profileCardPerson>
            <app-profile-overlay [profileData]="post.updateUser"></app-profile-overlay>
         </ng-template>
      </div>
   </ng-container>

   <!-- LATEST POST DESCRIPTION -->
   <ng-container *ngIf="showPostDescription">
      <div
         *ngIf="hasDescription && !post.snippet?.description?.trim()"
         class="description">
         {{ post.postPlainContent.trim() }}
      </div>
      <div
         *ngIf="hasDescription && !!post.snippet?.description?.trim()"
         class="description"
         [innerHTML]="post.snippet?.description | safeHtml"></div>
   </ng-container>

   <div
      class="attachments"
      *ngIf="showPostAttachments">
      <app-view-attachments [postAttachments]="post.postAttachments"></app-view-attachments>
   </div>

   <!-- LATEST POST LABELS -->
   <ng-container *ngIf="hasLabels && showPostLabels">
      <div class="labels">
         <app-post-labels
            [editorial]="post.editorialStatus"
            [legal]="post.postLegal"></app-post-labels>
      </div>
   </ng-container>
</ng-container>

<!-- STORY ONLY -->
<ng-container *ngIf="storyOnly">
   <div class="updated-by">
      <ng-container *ngIf="story.createUser?.firstName || story.createUser?.displayName">
         <button
            class="name"
            nz-popover
            [nzPopoverContent]="profileCardPerson"
            [nzPopoverPlacement]="index === 0 ? 'bottomLeft' : 'topLeft'">
            {{ Common.formatName(story.createUser, true) }}
         </button>

         <span class="time">
            posted on {{ utcToLocal(story.createDateTime, 'DATE') | date: 'M/d/yy' }} at
            {{ utcToLocal(story.createDateTime, 'TIME') }}
         </span>
      </ng-container>

      <ng-template #profileCardPerson>
         <app-profile-overlay [profileData]="story.createUser"></app-profile-overlay>
      </ng-template>
   </div>
</ng-container>

<!-- FOOTER -->
<div
   class="ncx-story-footer"
   *ngIf="showFooter">
   <div class="left-side">
      <!-- STORY TAGS -->
      <app-info-tags
         class="item"
         *ngIf="showNumTags"
         [total]="story.storyContentTags?.length || 0"></app-info-tags>

      <!-- POST COMMENTS -->
      <!-- <app-info-comments
      class="item"
      *ngIf="showNumComments"
      [total]="post?.repliesCount || 0"></app-info-comments> -->

      <!-- POST ATTACHMENTS -->
      <app-info-attachments
         class="item"
         *ngIf="showNumAttachments"
         [total]="post?.postAttachments?.length || 0"></app-info-attachments>

      <!-- SHARE -->
      <app-info-share
         class="item"
         *ngIf="showShare"
         (click)="onShare()"></app-info-share>
   </div>

   <div class="right-side">
      <!-- FOLLOWERS -->
      <app-follow-story
         *ngIf="story.storyId && showFollow"
         buttonSize="small"
         [storyId]="story.storyId"
         [isFollowing]="story.storyFollowerIds ? story.storyFollowerIds.includes(userId) : story.isUserFollowing"
         [isDiscussionPost]="story.isGroupPost"
         [followers]="story.storyFollowerCount"
         [showFollowCount]="false"
         [showFollowStatus]="true"
         (updatedFollower)="onFollow($event)"></app-follow-story>
   </div>
</div>

<!-- SHARE POST MODAL -->
<app-share-post
   [isVisible]="showShareModal"
   [postId]="post.postId"
   [message]="shareMessage"
   (closedSharedContent)="closeShareModal()"></app-share-post>
