<!-- RESULT COUNT -->
<div class="result-count dropdown-filter">
   <span>
      <nz-select
         class="dd-design"
         [(ngModel)]="searchService.filters.PEOPLE.sort"
         (ngModelChange)="onFilterChanged()">
         <nz-option
            nzValue="descending"
            nzLabel="A-Z"></nz-option>
         <nz-option
            nzValue="ascending"
            nzLabel="Z-A"></nz-option>
      </nz-select>
   </span>

   <span *ngIf="isLoaded || (total > 0 && !isLoaded)">
      {{ resultCount }}
      <button
         *ngIf="searchService.suggestedSearch.show"
         (click)="searchService.performSuggestedSearch()"
         class="suggested-search">
         Did you mean {{ searchService.suggestedSearch.keyword }}?
      </button>
   </span>
</div>

<app-scroll-container class="people-results-scroll-container">
   <ng-template bodyTemplate>
      <div
         [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
         infiniteScroll
         [infiniteScrollDistance]="scrollDistance"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         data-scrollable-container
         [attr.data-component]="'ncx/search/page/results/people'">
         <div class="search-result-body">
            <!-- LOADER -->
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isLoaded"></app-loader-component>

            <!-- CARD TO DISPLAY SEARCH RESULTS -->
            <div class="search-people-body">
               <ncx-profile-search
                  class="card-style"
                  *ngFor="let profile of searchResults; let index = index"
                  (viewProfile)="viewProfile(profile, index)"
                  [profile]="profile"></ncx-profile-search>
            </div>

            <div *ngIf="!searchResults.length && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Profiles Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </div>
   </ng-template>
</app-scroll-container>
