<div
   class="group-container"
   data-component="shared/cards/group/search">
   <div class="group-info">
      <!-- GROUP TITLE -->
      <p
         class="title"
         [innerHTML]="group.snippet?.title?.trim() || group.storyTitle | safeHtml"></p>

      <!-- NUMBER OF MEMBERS -->
      <p class="members">{{ group.membersCount }} member{{ group.membersCount !== 1 ? 's' : '' }}</p>
   </div>

   <!-- both href and click events are necessary in order
      for click events to work in ios-shell app -->
   <div class="icon-circle-outlined">
      <a
         [href]="group.storyUrl"
         (click)="onViewGroup()">
         <i
            class="nav-circle"
            nz-icon
            nzType="arrow-right"
            nzTheme="outline"></i>
      </a>
   </div>
</div>
