<div
   class="profile-container"
   data-component="shared/cards/profile/search">
   <div
      class="profile"
      nz-popover
      [nzPopoverContent]="profileCardTemplate"
      nzPopoverPlacement="topLeft">
      <div class="avatar">
         <!-- AVATAR (WITH PICTURE) -->
         <nz-avatar
            *ngIf="profile.thumbnailUrl"
            [nzSrc]="Common.formS3Link(profile.thumbnailUrl)"></nz-avatar>

         <!-- AVATAR (NO PICTURE - ICON ONLY) -->
         <nz-avatar
            *ngIf="!profile.thumbnailUrl"
            nzIcon="user"></nz-avatar>
      </div>

      <span>
         <!-- NAME -->
         <p
            class="name"
            [innerHTML]="profile.snippet?.title?.trim() || profileName | safeHtml"></p>

         <!-- JOB -->
         <p
            class="job"
            *ngIf="profile.jobTitle">
            {{ profile.jobTitle }}
         </p>
         <p>
            <button (click)="onViewProfile()">View Profile</button>
         </p>
      </span>
   </div>
</div>

<ng-template #profileCardTemplate>
   <app-profile-overlay
      [profileData]="{ userId: profile.userId, displayName: profile.displayName }"></app-profile-overlay>
</ng-template>
